import Vue from 'vue'
import App from './App.vue'
import store from './store/index'
import './assets/css/tailwind.css'
import './assets/css/main.css'

// widget setup
// (optional) 'Custom elements polyfill'
import 'document-register-element/build/document-register-element'

// vue-custom-element by karol-f
import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

Vue.config.productionTip = false

// use vue-custom-element
App.store = store

App.props  = ['title', 'apikey']

// App.router = router
Vue.customElement('prolipsis-widget', App)
