<template>

  <div v-show="showWidget" id="widget-main">


    <div class="overlay fixed top-0 bottom-0 left-0 right-0 bg-gray-800 opacity-20 z-50 flex items-center justify-center" >

    </div>

    <div class="content z-[60] fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center" @click.self="$emit('hide')">
      <div class="relative w-11/12 h-5/6 md:h-3/6 xl:w-8/12 xl:h-8/12 2xl:w-1/2 2xl:h-1/2 bg-white rounded-xl text-gray-800 opacity-100
overflow-y-auto">

        <div class="absolute top-3 left-3"><img src="https://athlisis-new.s3.eu-north-1.amazonaws.com/logo_d7e7ace923.svg" class="w-[120px] "></div>


        <loading-state  v-if="dashboardState===0"></loading-state>


        <div v-if="dashboardState === 1" class="h-full py-4 px-5 md:px-10 mt-[60px]">
          <intro-text class="mb-6 "></intro-text>
          <label for="small-input" class="block mb-2 text-sm font-medium text-gray-900">Εισάγετε το e-mail σας για να συνεχίσετε</label>
          <input v-model="user.email" type="text" id="small-input"
                 class="block p-2 w-full text-gray-800 bg-gray-50 rounded-lg border border-gray-800 sm:text-xs focus:ring-gray-800"
                 :class="hasError ? 'form-error' : ''" placeholder="Το e-mail σας">


          <button  type="button" class="py-2 ml-auto px-3 my-6 text-xs font-medium text-center text-white bg-blue-700
               rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 "
                   :disabled="!user.email || !validateEmail(user.email)" @click="fetchCoupon">Συνέχεια</button>

          <p v-if="error && error !== ''" class="text-red-700">
            {{error}}
          </p>

<!--          <div class="flex h-full items-center justify-center">-->

<!--            <div class="mx-auto p-4 ">-->

<!--         -->
<!--            </div>-->

<!--          </div>-->

        </div>


        <div v-if="dashboardState === 2" class="grid md:grid-cols-2 h-full relative">

          <div class="absolute top-2 right-2 md:right-[50%] text-xs cursor-pointer bg-white p-2" @click="changeAccount">
            Αλλαγή λογαριασμού
          </div>
          <div>
            <div class="pt-12"></div>
            <hr >
            <div class="pl-6 pt-3">
              <h2 class="text-normal text-gray-800 inline mr-2">Καλωσόρισες, <strong>{{user.name}}</strong></h2>
            </div>

            <div class="bg-gray-100 absolute top-4 left-[140px] block py-1 w-fit px-3 text-xs rounded ">{{user.coupon}}</div>

            <div v-if="prescriptionItems.length > 0" class="pl-6 pt-3 md:h-1/2 md:overflow-y-auto">


              <div v-for="(item,index) in prescriptionItems" :key="item.slug"
                   class="grid grid-cols-2 p-2" :class="index % 2 !== 0 ?'bg-gray-50' : ''">

                <div class="mr-3">
                  <span class="text-[7px] font-light block">Όνομα προϊόντος</span>
                  <p class="text-sm">{{item.name}}</p>
                </div>
                <div>
                  <span class="text-[7px] font-light block">Ποσότητα</span>
                  <p class="font-bold text-sm">{{item.quantity}}</p>
                </div>
              </div>
            </div>
            <div v-if="prescriptionItems.length > 0" class="mt-4 pl-6">
              <button  type="button" class="py-1 mr-2 px-2 text-xs font-medium text-center text-gray-800 bg-transparent
               rounded-lg hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 "
                       @click="clearList">Εκκαθάριση λίστας</button>
              <button  type="button" class="py-2 px-3 text-xs font-medium text-center text-white bg-blue-700
               rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 "
                       @click="generateQR">Δημιουργία λίστας (QR)</button>
            </div>

          </div>
          <div class="bg-gray-50 rounded-r-xl md:max-h-full md:overflow-y-auto">

            <div class="p-3">
              <h2 class="text-normal text-gray-800 inline mr-2 mb-3">Λίστα προϊόντων</h2>
              <div v-if="product.stock_quantity > 0" v-for="product in products" :key="product.id" class="shadow my-2 p-2 flex bg-white rounded items-center">

                  <div class="h-[50px] w-[50px] shrink-0 bg-cover mr-3 bg-no-repeat bg-center rounded" :style="`background-image:url('${product.featured_image}')`"></div>
                  <div class="mr-3 w-[70%]">
                    <span class="text-[7px] font-light block">Όνομα προϊόντος</span>
                    <span class="font-normal text-sm">{{product.name}}</span>
                  </div>
                <div class="px-3 ml-auto w-[20%]">
                  <span class="text-[7px] font-light block">Τιμή</span>
                  <div v-if="product.sale_price">
                    <span class="text-xs line-through font-light mr-1">{{calculatePrice(product.price, product.tax_percentage)}}€</span>
                    <br>
                    <span class="text-sm font-bold">{{calculatePrice(product.sale_price, product.tax_percentage)}}€</span>
                  </div>
                  <div v-else>
                    <span class="text-sm font-bold">{{calculatePrice(product.price, product.tax_percentage)}}€</span>
                  </div>

                </div>
                <div class="ml-auto !pl-2 !pr-0 w-[10%]">
                  <button  type="button" class="!py-1 ml-auto !px-2 text-xs font-medium text-center text-white bg-blue-700
               rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 "
                           @click="addItem(product)">+</button>

                </div>
                <div class="ml-1">
                  <button  type="button" class="!py-1  ml-auto !px-2 text-xs font-medium text-center text-white bg-red-700
               rounded-lg hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-blue-300 "
                           :disabled="!showRemoveItem(product)"
                           @click="removeItem(product)">-</button>
                </div>

              </div>
            </div>


          </div>
        </div>


        <prescription-page :products="prescriptionItems" :coupon="user.coupon" :url="url" v-if="dashboardState === 3" @goBack="dashboardState = 2"></prescription-page>

      </div>
    </div>

  </div>
</template>
<style scoped>
.form-error{
  @apply ring-red-700 !border-red-700 focus:border-red-700;
}
button:disabled{
  @apply opacity-50;
}
</style>

<script>

import axios from "axios"
import LoadingState from "@/components/LoadingState";
import PrescriptionPage from "@/components/PrescriptionPage";
import IntroText from "@/components/IntroText";

export default{
  components: {IntroText, PrescriptionPage, LoadingState},
  props:{
    showWidget: Boolean,
    languageChanged:Number
  },

  watch:{
    'user.email'(val){
      if(val){
        localStorage.setItem('widget.email', val);
      }
    },
    prescriptionItems(val){
      if(val) localStorage.setItem('widget.prescription', JSON.stringify(val));
    }
  },
  computed:{

  },
  data(){
    return{
      dashboardState: 0,
      products: [],
      productCount: 0,
      loading: false,
      user: {
        email: null,
        name: null,
        coupon: null
      },
      prescriptionItems: [],
      hasError: false,
      url: '',
      error: ''
    }
  },


  mounted(){

    this.user.email = localStorage.getItem('widget.email');
    if(localStorage.getItem('widget.prescription')){
      this.prescriptionItems = JSON.parse(localStorage.getItem('widget.prescription'));
    }
    if(!this.user.email){
      this.dashboardState = 1;
    }else{
      this.fetchCoupon()
    }

  },
  methods: {

    fetchCoupon(){

      this.hasError = false;
      this.error = null;
      return axios.get(process.env.VUE_APP_PROLIPSIS_API_URL + '/api/prescriber/public/voucher', {params:{email: this.user.email}}).then(res=>{
        this.user.coupon = res.data.coupon;
        this.user.name = res.data.name;
        this.dashboardState = 0;
        this.fetchProducts();
        if(!this.hasError){
          setTimeout(()=>{
            this.dashboardState = 2;
          }, 500)
        }else{
          this.dashboardState = 1;
        }
      }).catch(e=>{
        this.hasError = true;
        this.error = 'Το e-mail σας δεν είναι εγγεγραμμένο στο σύστημα συνεργατών.'
        this.dashboardState = 1;
      })

    },

    fetchProducts(){

      return axios.get(process.env.VUE_APP_PROLIPSIS_API_URL + '/api/products', {params: {limit: 200, offset: 0}}).then(res=>{
        this.products = res.data.rows;
        this.productCount = res.data.count;
      }).catch(e=>{
        console.log(e);
      })
    },

    changeAccount(){
      this.dashboardState = 0;
      setTimeout(()=>{

        this.user.email = null;
        this.user.coupon = null;
        this.user.name = null;
        localStorage.removeItem('widget.email');
        this.dashboardState = 1;

      }, 500)
    },

    validateEmail (email) {
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },

    calculatePrice(price, tax){
      return (price* (tax || 1.24)).toFixed(2);
    },

    showRemoveItem(product){
      return this.prescriptionItems.find(x => x.slug === product.slug)
    },

    clearList(){

      this.prescriptionItems = [];
    },
    generateQR(){
      this.dashboardState = 0; // show loading bar
      let items = {}
      for(let item of this.prescriptionItems){

        items[item.slug] = item.quantity
      }
      this.url = process.env.VUE_APP_PROLIPSIS_SHOP_URL + '?products=' + encodeURIComponent(JSON.stringify(items)) + '&coupon=' + this.user.coupon;
      setTimeout(()=>{
        this.dashboardState = 3;
      }, 300)



    },
    addItem(product){

      if(this.prescriptionItems.find(x => x.slug === product.slug)){
        this.prescriptionItems.find(x => x.slug === product.slug).quantity ++;
      }else{
        this.prescriptionItems.push({
          featured_image: product.featured_image,
          name: product.name,
          quantity: 1,
          slug: product.slug
        })
      }
    },

    removeItem(product){

      let item = this.prescriptionItems.find(x => x.slug === product.slug);
      if(!item) return;


      if(item.quantity === 1){
        this.prescriptionItems = this.prescriptionItems.filter(x => x.slug !== product.slug)
      }else{
        item.quantity--;
      }
    }


  }

}
</script>