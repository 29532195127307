<template>
  <div id="prolipsis-app">

    <div class="prolipsis-widget-toggle hover:shadow-lg flex items-center cursor-pointer
    border-2 border-transparent  justify-center bg-blue-100 fixed bottom-[100px] right-[20px]
     z-50 md:z-[1000] !px-4 !py-4 text-white rounded-full
hover:scale-110 ease-in-out duration-300"
         :class="showWidget ? 'active-widget': ''"
    @click="showWidget = !showWidget">

      <div class="text-center mb-0 pb-0 ">
        <img src="https://athlisis-new.s3.eu-north-1.amazonaws.com/favicon_153c94d878.png" class="w-[35px] h-[35px] !m-0 !p-0 ">
      </div>

    </div>
    <widget-main v-if="loaded" :show-widget="showWidget" @hide="showWidget = false"></widget-main>
  </div>
</template>

<style lang="scss" scoped>
#prolipsis-app .active-widget{
  @apply border-gray-800;
}
#prolipsis-app{
  .logo-img{
    width:200px;
    @media(max-width:767px){
      width:150px;
    }
  }


}
</style>

<script>
import Header from "./components/Header";
import WidgetMain from "./views/WidgetMain";
export default {
  props: ['title'],
  components:{WidgetMain, Header},
  data () {
    return {
      activeTab: '',
      key: 0,
      showWidget: false,
      loaded: false
    }
  },

  mounted(){

    let queryParams = new URLSearchParams(window.location.search);

    if(queryParams.get('email')){
      localStorage.setItem('widget.email', queryParams.get('email'));
    }
    if(queryParams.get('show_widget') && queryParams.get('show_widget') === 'true'){
      this.showWidget= true;
    }
    this.loaded = true;
  }
}
</script>
