<template>

  <div id="intro-text">
    <p>Κέρδισε χρήματα μέσα από το <strong>Prolipsis</strong> powered by <strong>Athlisis</strong></p>
    <p>Ως συνεργάτης του επιστημονικού λογισμικού Athlisis, σου δίνουμε μία πολύ σημαντική δυνατότητα να κερδίζεις χρήματα μέσω των προτάσεων για συμπληρώματα που πραγματοποιείς.</p>
    <p>Σε κάθε παραγγελία, ως εγγεγραμμένος συνεργάτης, έχεις μοναδικό κωδικό και μπορείς να κερδίζεις <strong>15% σε κάθε παραγγελία και ο πελάτης σου έκπτωση 5%. </strong>Πρακτικά όσο κάθε πελάτης χρησιμοποιεί τον μοναδικό κωδικό σου, εσύ κερδίζεις 15% από τις παραγγελίες που κάνει ο πελάτης για αυτόν και τους οικείους του.</p>
    <p>Μπορείς να συνδεθείς και να δεις το υπερσύγχρονο περιβάλλον που έχουμε δημιουργήσει για εσένα ώστε να βλέπεις τι πωλήσεις γίνονται με τον μοναδικό κωδικό σου και τα στατιστικά του κάθε μήνα.</p>
    <p class="font-bold">Σημείωση: Σας έχουμε στείλει e-mail για το πώς να αποκτήσετε πρόσβαση στο λογαριασμό σας (αλλαγή κωδικού / σύνδεση με google).</p>
  </div>
</template>
<style>
#intro-text p{
  @apply mb-3 text-sm;
}
</style>
<script>
export default{

}
</script>