<template>
  <div id="chart-header" class="row chart-header mobile-center">
    <div class="col-0 col-md-6 chart-header-title">
      <p class="mt-0">
        <strong>{{title}}</strong>
      </p>

    </div>
    <div class="col-12 col-md-6 text-right">
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
export default{

  components: {},

  watch:{
    language(){
      this.toggleKey +=1;
    }
  },

  computed:{
    ...mapState({
      title: state => state.title,
      language: state => state.language
    })
  },
  data(){
    return{
      toggleKey: 0
    }
  }
}
</script>