<template>
  <div  class="h-full overflow-y-auto">


    <div class="pt-[65px] md:pt-[30px] lg:pt-[25px]">

      <div class="mx-auto text-center px-5">

        <div class="md:w-8/12 mx-auto">
          <h2>Η λίστα δημιουργήθηκε επιτυχώς!</h2>
          <p class="text-xs">Παρακάτω μπορείτε να δείτε το QR προς αποστολή στον πελάτη σας, ή τον σύνδεσμο που μπορείτε να στείλετε για να ολοκληρώσει
            την αγορά του.</p>
        </div>

        <div v-if="showSendToCustomer" class="grid md:grid-cols-12 gap-5 items-center lg:w-8/12 mx-auto">

          <div class="md:col-span-8">
            <input v-model="customer_email" type="text"
                   class="p-2 w-full text-gray-800 bg-gray-50 rounded-lg border border-gray-800 sm:text-xs focus-visible:ring-gray-800 focus:!ring-gray-800"
                   placeholder="Ε-mail του πελάτη σας">
          </div>

          <div class="md:col-span-4">
            <button  type="button" class="py-2 w-full ml-auto px-3 text-xs font-medium text-center text-white bg-blue-700
              rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:!ring-blue-300" @click="sendEmailToCustomer" >Αποστολή μέσω e-mail</button>
          </div>




        </div>

        <div v-if="message" class="my-2" v-html="message"></div>

<!--        -->
<!--        <input v-model="url" type="text" id="small-input"-->
<!--               class="block p-2 w-full my-6 lg:w-8/12 mx-auto text-gray-800 bg-gray-200 rounded-lg border-1 border-transparent sm:text-xs focus:ring-gray-800"-->
<!--               disabled>-->

        <div>

          <vue-q-r class="mt-6" id="qr" :value="url" :size="'150'" level="H" @click.native="copyToClipboard" />
          <button class=" copy-btn rounded-lg"
                  :class="copied ? 'copied' : 'not-copied'"
                  @click="copyToClipboard"> {{ copied ? '✔️ Αντιγράφηκε στο πρόχειρο' : '💾 Αντιγραφή συνδέσμου' }}</button>
        </div>



        <button  type="button" class="py-2 ml-auto px-3 my-6 text-xs font-medium text-center text-white bg-blue-700
               rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 " @click="$emit('goBack')"
              >Επιστροφή στην σελίδα επιλογής προϊόντων</button>

      </div>

    </div>

  </div>
</template>

<style>
#qr canvas{
  @apply mx-auto;
}
.copy-btn.not-copied{
  @apply bg-gray-300;
}
.copy-btn.copied{
  @apply bg-green-50 ;
}
</style>


<script>

import VueQR from "qrcode.vue"
export default{
  components:{VueQR},

  props:{
    products: Array,
    coupon: String,
    url: String
  },
  data(){
    return{
      showSendToCustomer: true,
      customer_email: null,
      message: null,
      copied: false
    }
  },
  mounted(){

    this.message = null;
    if(localStorage.getItem('widget.customer_email')){
      this.customer_email = localStorage.getItem('widget.customer_email');
    }

  },
  methods:{

    sendEmailToCustomer(){

      if(!this.customer_email || this.customer_email === '') return;

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          products: this.products,
          coupon: this.coupon,
          email: this.customer_email
        })
      };

      fetch(process.env.VUE_APP_PROLIPSIS_API_URL + '/api/general/send-qr-to-client', requestOptions).then((res) =>{

        if(res.ok){
          this.showSendToCustomer =  false;
          this.message = '<p>Το e-mail στάλθηκε επιτυχώς</p>'
        }else{
          res.json().then(d => {
            this.message = '<p class="text-red-700">' + d ? d.message : 'Κάτι πήγε λάθος' + '</p>'
          });
        }
      });

    },

    copyToClipboard(){
      navigator.clipboard.writeText(this.url);
      this.copied = true;
    }

  }
}
</script>